const deutsch = {
    'COLLECTION.Button_Create' : 'Neue Kollektion',
    'COLLECTION.Modal_Title_Create' : 'Kollektion erstellen',
    'COLLECTION.Title_EditCollection' : 'Kollektion ändern',
    'COLLECTION.Type_Private' : 'Privat',
    'COLLECTION.Type_Public' : 'Öffentlich',
    'COLLECTION.Text_YourDefault' : 'Deine private Kollektion',
    'COLLECTION.Link_Text_Edit' : 'Kollektion ändern',
    'COLLECTION.Modal_Title_Remove' : 'Kollektion entfernen',
    'COLLECTION.Button_Delete' : 'Kollektion löschen',
    'COLLECTION.Text_ModalDelete_Question' : 'Diese Kollektion endgültig löschen?',
    'COLLECTION.Title_Default' : 'Standard Kollektion',
    'COLLECTION.Text_PostCount' : '{ postCount } Posts',
    'COLLECTION.Text_YourCollection' : 'Deine Kollektion',
    'COLLECTION.Modal_Title_AddPost' : 'Zur Kollektion hinzufügen',
    'FOLLOW.Button_Text_Follow' : 'Folgen',
    'FOLLOW.Button_Text_Unfollow' : 'Nicht mehr folgen',
    'FOLLOW.Button_Text_Following' : 'Folge Ich',
    'FOLLOW.Button_Text_FollowCollection' : 'Kollektion folgen',
    'FOLLOW.Button_Text_UnfollowCollection' : 'Kollektion nicht mehr folgen',
    'FOLLOW.Button_Text_FollowHashtag' : 'Hashtag folgen',
    'FOLLOW.Button_Text_UnfollowHashtag' : 'Hashtag nicht mehr folgen',
    'FOLLOW.Type_Label_Collections' : 'Kollektionen',
    'FOLLOW.Type_Label_Users' : 'Nutzer',
    'FOLLOW.Type_Label_Hashtags' : 'Hashtags',
    'FORM_RULES.Required_Username' : 'Bitte Nutzername eingeben!',
    'FORM_RULES.Required_Password' : 'Bitte Passwort eingeben!',
    'FORM_RULES.Email' : 'Keine gültige E-Mail!',
    'FORM_RULES.Required_Email' : 'Bitte E-Mail eingeben!',
    'FORM_RULES.Min_FullName' : 'Name muss mindestens { minLength } Zeichen haben',
    'FORM_RULES.Required_FullName' : 'Bitte vollständigen Namen eingeben',
    'FORM_RULES.Min_Username' : 'Nutzername muss mindesten { minLength } Zeichen haben',
    'FORM_RULES.RegExp_Username' : 'Nur kleine Buchstaben erlaubt',
    'FORM_RULES.Min_Password' : 'Kennwort muss mindestens { minLength } Zeichen haben',
    'FORM_RULES.Required_NewPassword' : 'Neues Kennwort eingeben',
    'FORM_RULES.Required_Gender' : 'Geschlecht',
    'FORM_RULES.Required_Language' : 'Sprache wählen',
    'FORM_RULES.ContactPhone' : 'Nur Zahlen erlaubt',
    'FORM_RULES.Required_CurrentPassword' : 'Bitte aktuelles Kennwort eingeben',
    'FORM_RULES.Required_ConfirmPassword' : 'Bitte Kennwort bestätigen',
    'FORM_RULES.Required_PasswordInconsistent' : 'Die Kenwörten stimmen nicht überein!',
    'FORM_RULES.Text_UploadImage' : 'Laden Sie hochwertige Bilddateien mit jeweils weniger als { size } hoch',
    'FORM_RULES.Text_UploadVideo' : 'Laden Sie hochwertige Videodateien mit jeweils weniger als { size } hoch',
    'FORM_RULES.Attachment_Size' : 'Datei Upload mit maximal { size } Mb pro Datei.',
    'FORM_RULES.Required_CollectionSlug' : 'Kollektion Bezeichnung eingeben',
    'FORM_RULES.Min_CollectionSlug' : 'Bezeichnung muss  mindestens { minLength } Zeichen haben',
    'FORM_RULES.RegExp_CollectionSlug' : 'Nur kleine Buchstaben und Zahlen erlaubt',
    'FORMS.Title_Login' : 'Anmelden',
    'FORMS.Input_Label_Username' : 'Nutzername',
    'FORMS.Input_Label_Password' : 'Kennwort',
    'FORMS.Button_Text_Login' : 'Anmelden',
    'FORMS.Text_Registration_Question' : 'Neuer Nutzer?',
    'FORMS.Link_Text_Registration' : 'Anmelden',
    'FORMS.Link_Text_ForgotPassword' : 'Kennwort vergessen?',
    'FORMS.Link_Text_ForgotPassword2' : 'Senden',
    'FORMS.Link_Text_Login' : 'Anmelden',
    'FORMS.Title_Registration' : 'Registrieren',
    'FORMS.Input_Label_Email' : 'E-Mail',
    'FORMS.Input_Label_FullName' : 'vollständiger Name',
    'FORMS.Button_Text_Register' : 'Registrieren',
    'FORMS.Text_Login_Question' : 'Nutzerkonto vorhanden?',
    'FORMS.Button_Text_ForgotPassword' : 'Kennwort vergessen - Link senden',
    'FORMS.Title_ForgotPassword' : 'Kennwort vergessen',
    'FORMS.Success_Text_ForgotPassword' : 'Wir haben dir einen Kennwort recovery Link per E-Mail gesendet!',
    'FORMS.Input_Label_NewPassword' : 'Neues Kennwort',
    'FORMS.Title_ChangePassword' : 'Kennwort ändern',
    'FORMS.Text_ChangePassword_Question' : 'Weißt du noch den Kennwort?',
    'FORMS.Text_ForgotPassword_Question' : 'Neue Anfrage schicken?',
    'FORMS.Loading_Text_ChangePassword' : 'Bitte warten, wir checken deinen token!',
    'FORMS.Select_OptionLabel_Male' : 'Männlich',
    'FORMS.Select_OptionLabel_Female' : 'Weiblich',
    'FORMS.Select_Label_Gender' : 'Geschlecht',
    'FORMS.Select_Label_Language' : 'Sprache',
    'FORMS.Textarea_Label_About' : 'Über',
    'FORMS.Input_Placeholder_FullName' : 'Vollständigen Namen eingeben',
    'FORMS.Input_Placeholder_Username' : 'Nutznamen eingeben',
    'FORMS.Input_Placeholder_Email' : 'E-Mail eingeben',
    'FORMS.Input_Placeholder_Gender' : 'Geschlecht wählen',
    'FORMS.Input_Placeholder_Language' : 'Sprache wählen',
    'FORMS.Input_Placeholder_About' : 'Kurze Info über dich!',
    'FORMS.Input_Placeholder_ContactEmail' : 'E-Mail Adresse eingeben',
    'FORMS.Input_Placeholder_ContactPhone' : 'Handynummer eingeben',
    'FORMS.Input_Label_ContactPhone' : 'Telefonnummer',
    'FORMS.Input_Label_ContactEmail' : 'E-Mail',
    'FORMS.Button_Text_ChangePassword' : 'Kennwort ändern',
    'FORMS.Input_Placeholder_CurrentPassword' : 'Aktuelles Kennwort eingeben',
    'FORMS.Input_Placeholder_NewPassword' : 'Neues Kennwort eingeben',
    'FORMS.Input_Placeholder_ConfirmPassword' : 'Neues Kennwort wiederholen',
    'FORMS.Input_Label_CurrentPassword' : 'Altes Kennwort',
    'FORMS.Input_Label_ConfirmPassword' : 'Nochmal eingeben',
    'FORMS.Input_Label_Title' : 'Titel',
    'FORMS.Input_Label_Description' : 'Beschreibung',
    'FORMS.Input_Label_CollectionName' : 'Kollektions Name',
    'FORMS.Upload_Label_Cover' : 'Cover',
    'FORMS.Input_Label_CollectionUniqueName' : 'Kollektions Bezeichnung',
    'FORMS.Checkbox_Label_CollectionPublic' : 'Kollektion öffentlich schalten',
    'FORMS.Input_Placeholder_Slug' : 'Spezifischen Namen für URL',
    'FORMS.Select_OptionLabel_Management' : "Geschäftsführung",
    'FORMS.Select_OptionLabel_ITManagement' : "IT-Management",
    'FORMS.Select_OptionLabel_FinancialAccounting' : "Finanzbuchhaltung",
    'FORMS.Select_OptionLabel_LegalCounsel' : "Justitiar",
    'FORMS.Select_OptionLabel_MarketingAndSales' : "Marketing und Vertrieb",
    'FORMS.Select_OptionLabel_SystemStandConstruction' : "System Standbau",
    'FORMS.Select_OptionLabel_IndividualStandConstruction' : "Individueller Standbau",
    'FORMS.Select_OptionLabel_WarehousingAndLogistics' : "Lager und Logistik",
    'FORMS.Select_OptionLabel_ConstructionManagement' : "Technische Bauleitung",
    'FORMS.Select_OptionLabel_AssemblyAndTransport' : "Montage und Transporte",
    'FORMS.Select_OptionLabel_Draft' : "Entwurf",
    'FORMS.Select_OptionLabel_CentralPurchasing' : "Zentraleinkauf",
    'FORMS.Select_OptionLabel_Secretariat' : "Sekretariat",
    'FORMS.Select_OptionLabel_Personnel' : "Personal",
    'FORMS.Input_Label_Department' : 'Unternehmensbereich',
    'FORMS.Input_Label_JobDescription' : 'Aufgabenbezeichnung',
    'FORMS.Input_Label_WorkingOn' : 'Ich bin verantwortlich für:',
    'FORMS.Input_Placeholder_Department' : 'Wählen Sie Ihre Abteilung',
    'FORMS.Input_Placeholder_JobDescription' : 'Wählen Sie Ihre Stellenbeschreibung',
    'FORMS.Select_OptionLabel_CEO' : "Geschäftsführer",
    'FORMS.Select_OptionLabel_CTO' : "Bereichsleiter IT",
    'FORMS.Select_OptionLabel_PM'  : "Projekt Manager(in)",
    'FORMS.Select_OptionLabel_PMCalculator'   : "Projekt Manager(in)/Kalkulator(in)",
    'FORMS.Select_OptionLabel_PMCADDraftsman' : "Projekt Manager(in)/ CAD- Konstrukteur(in)",
    'FORMS.Select_OptionLabel_CADDraftsman'   : "CAD- Konstrukteur(in)",
    'FORMS.Select_OptionLabel_ProjectAssistant' : "Projekt- Assistent(in)",
    'FORMS.Select_OptionLabel_DevelopmentTeamLead' : "Teamleiter Softwareentwicklung",
    'FORMS.Select_OptionLabel_SeniorBackendDeveloper' : "Senior Softwareentwickler",
    'FORMS.Select_OptionLabel_SoftwareDeveloper'   : "Softwareentwickler",
    'FORMS.Select_OptionLabel_SystemAdministrator' : "System- Administrator",
    'FORMS.Select_OptionLabel_HeadSystemsStandConstruction'   : "Bereichsleiter Systemstandbau",
    'FORMS.Select_OptionLabel_HeadIndividualStandConstruction' : "Bereichsleiter individual Standbau",
    'FORMS.Select_OptionLabel_LogisticsEmployee'   : "Logistikmitarbeiter(in)",
    'FORMS.Select_OptionLabel_FinancialAccountantReceivable' : "Finanzbuchhalter(in) / Debitoren",
    'FORMS.Select_OptionLabel_FinancialAccountantPayable' : "Finanzbuchhalter(in) / Kreditoren",
    'FORMS.Select_OptionLabel_FinancialAccountantHR' : "Finanzbuchhalter(in) / Personal",
    'FORMS.Select_OptionLabel_SalesMarketing' : "Leiter(in) Vertrieb & Marketing",
    'FORMS.Select_OptionLabel_Clerk' : "Sachbearbeiter(in)",
    'FORMS.Select_OptionLabel_Secretary' : "Sekretär(in)",
    'GLOBAL.Button_Text_Confirm' : 'Bestätigen',
    'GLOBAL.Button_Text_Yes' : 'Ja ',
    'GLOBAL.Button_Text_No' : 'Nein',
    'GLOBAL.Text_InactiveUser' : 'Inaktiver Nutzer',
    'GLOBAL.Button_Text_Cancel' : 'Abbrechen',
    'GLOBAL.Text_ItsYou' : 'Hej das bist Du',
    'GLOBAL.Link_Text_EditProfile' : 'Profil ändern',
    'GLOBAL.Title_EditProfile' : 'Profil ändern',
    'GLOBAL.Button_Text_ChangePhoto' : 'Foto ändern',
    'GLOBAL.Button_Text_Save' : 'Speichern',
    'GLOBAL.Title_AdditionalInfo' : 'Job Profil',
    'GLOBAL.Title_ChangePassword' : 'Kennwort ändern',
    'GLOBAL.Button_Text_Next' : 'Weiter',
    'GLOBAL.Button_Text_Publish' : 'Veröffentlichen',
    'GLOBAL.Button_Text_SaveDraft' : 'Entwurf speichern',
    'GLOBAL.Button_Text_Done' : 'Fertig',
    'GLOBAL.Button_Text_Create' : 'Erstellen',
    'GLOBAL.Button_Text_ChangeCover' : 'Cover ändern',
    'GLOBAL.Link_Text_OnError' : 'Zur Timeline',
    'GLOBAL.Title_Followers' : '{ slug } Followers',
    'GLOBAL.Title_Following' : '{ slug } Ich folgen',
    'GLOBAL.Link_Text_OnDraftError' : 'Zum Entwurf',
    'GLOBAL.Text_SystemError' : 'Ups nicht mein Fehler, bitte die Seite neu laden oder den Admin nerven!',
    'GLOBAL.Link_Text_SystemError' : 'Zurück',
    'GLOBAL.Text_Loading' : 'Lade…',
    'GLOBAL.Button_Text_More' : 'mehr',
    'MENUS.Label_News' : 'News',
    'MENUS.Label_MyTimeline' : 'Meine Timeline',
    'MENUS.Label_Search' : 'Suche',
    'MENUS.Label_Profile' : 'Profil',
    'MENUS.Label_NewPost' : 'Neuer Post',
    'MENUS.Button_Text_CopyLink' : 'Link kopieren',
    'MENUS.Button_Text_Cancel' : 'Abbrechen',
    'MENUS.Label_EditPost' : 'Post ändern',
    'MENUS.Label_DeletePost' : 'Post löschen',
    'MENUS.Link_Text_GoCollection' : 'Zur Kollektion',
    'MENUS.Link_Text_GoHashtag' : 'Zum Hashtag',
    'MENUS.Label_Posts' : 'Posts',
    'MENUS.Label_DraftPosts' : 'Entwürfe',
    'MENUS.Label_Collections' : 'Kollektionen',
    'MENUS.Label_Documents' : 'Dokumente',
    'MENUS.Label_Users' : 'Nutzer',
    'MENUS.Label_Hashtags' : 'Folgen',
    'MENUS.Label_Following' : 'Folge Ich',
    'MENUS.Label_Followers' : 'Folgen Mir',
    'MENUS.Label_EditProfile' : 'Profil ändern',
    'MENUS.Label_AdditionalInfo' : 'Job Profil',
    'MENUS.Label_ChangePassword' : 'Kennwort ändern',
    'MENUS.Button_Text_Logout' : 'Abmelden',
    'MENUS.Label_Settings' : 'Einstellungen',
    'MENUS.Label_EditCollection' : 'Kollektion ändern',
    'MENUS.Label_Notifications' : 'Meldungen',
    'NO_DATA.Text_Timeline' : 'Keine Posts in deiner Timeline',
    'NO_DATA.Link_Text_TimelineCreatePost' : 'Dein erstes Post erstellen?',
    'NO_DATA.Link_Text_TimelineSearch' : 'Willst du jemanden folgen?',
    'NO_DATA.Text_Likes' : 'Keiner mag diesen Post!',
    'NO_DATA.Select_Chat' : 'Bitte wählen Sie einen Chat aus,{ br }um mit dem Versenden von Nachrichten zu beginnen',
    'NO_DATA.Text_Search_Posts' : 'Wir konnten keine Posts { br } gemäß deiner Suche finden.',
    'NO_DATA.Text_Search_Users' : 'Wir konnten keine Nutzer { br } gemäß deiner Suche finden.',
    'NO_DATA.Text_Search_Hashtags' : 'Wir konnten keine Hashtags { br } gemäß deiner Suche finden.',
    'NO_DATA.Text_Search_Collections' : 'Wir konnten keine Kollektionen { br } gemäß deiner Suche finden',
    'NO_DATA.Text_Search_Documents' : 'Wir konnten keine Dokumente { br } gemäß deiner Suche finden',
    'NO_DATA.Text_Notifications_All' : 'Du hast keine Benachrichtigungen',
    'NO_DATA.Text_Notifications_Unread' : 'Du hast keine ungelesenen Benachrichtigungen',
    'NO_DATA.Link_Text_UserCreatePost' : 'Neuen Post erstellen?',
    'NO_DATA.Text_UserPosts' : '{ who } hat noch keinen Posts',
    'NO_DATA.Text_UserDraftPosts' : 'Du hast keine Entwurf- Posts',
    'NO_DATA.Text_UserCollections' : '{ who } hat noch keine Kollektionen',
    'NO_DATA.Text_UserDocuments' : '{ who } hat noch keine Dokumente',
    'NO_DATA.Text_NewsTimeline' : 'Es gibt keine Posts in NEWS',
    'NO_DATA.Text_Who_User' : 'Nutzer',
    'NO_DATA.Text_Who_You' : 'Du',
    'NO_DATA.Text_UserFollowers' : '{ who } hat keine Followers',
    'NO_DATA.Text_HashtagFollowers' : 'Dieser Hashtag hat keine followers.',
    'NO_DATA.Text_CollectionFollowers' : 'Diese Kollektion hat keine followers.',
    'NO_DATA.Text_HashtagFollow' : '{ who } folgt keinen Hashtags',
    'NO_DATA.Text_CollectionFollow' : '{ who } folgt keiner Kollektion',
    'NO_DATA.Text_UserFollow' : '{ who } folgt keinem Nutzer',
    'NO_DATA.Text_HashtagPosts' : 'Es gibt noch keine Posts zu diesem Hashtag.',
    'NO_DATA.Text_CollectionPosts' : 'Es gibt noch keine Posts in dieser Kollektion.',
    'NO_DATA.Text_Search' : 'Wir konnten nichts { br } gemäß deiner Suche finden.',
    'NOTIFICATIONS.Drawer_Title' : 'Meldungen',
    'NOTIFICATIONS.Filter_Label_All' : 'Alle',
    'NOTIFICATIONS.Filter_Label_Unread' : 'Ungelesen',
    'POST.Text_ModalDelete_Question' : 'Diesen Post wirklich löschen?',
    'POST.Button_Text_DeletePost' : 'Post löschen',
    'POST.Modal_Title_Delete' : 'Post entfernen',
    'POST.Modal_Title_Share' : 'Post teilen',
    'POST.Button_Text_Share' : 'Teilen',
    'POST.Button_Text_Shared' : 'Geteilt',
    'POST.Button_Text_Like' : '{ likeCount } like',
    'POST.Button_Text_Likes' : '{ likeCount } likes',
    'POST.Modal_Title_Likes' : 'Likes',
    'POST.Button_Text_ViewComments' : 'Alle { commentCount } Kommentare anschauen',
    'POST.Drawer_Title_Comments' : 'Kommentare',
    'POST.Modal_Title_Attachments' : 'Hinzugefügte Dateien',
    'POST.Button_Text_Attachment' : '{ attachmentCount } Dokument wurde hinzugefügt',
    'POST.Button_Text_Attachments' : '{ attachmentCount } Dokumente wurden hinzugefügt',
    'POST.Input_Placeholder_Comments' : 'Kommentar hinzufügen',
    'POST.Link_Text_ViewPost' : 'Post anschauen',
    'POST.Link_Text_DownloadAttachment' : 'Download',
    'POST.Title_ChooseImage' : 'Bild auswählen',
    'POST.Title_ChooseMedia' : 'Wählen Sie Medien',
    'POST.Text_AddImage' : 'Bild hinzufügen',
    'POST.Text_AddVideo' : 'Video hinzufügen',
    'POST.Type_Post' : 'Post',
    'POST.Type_News' : 'News',
    'POST.Title_PostDetails' : 'Post details',
    'POST.Title_CreatePost' : 'Post erstellen',
    'POST.Title_EditPost' : 'Post ändern',
    'POST.Drawer_Title_Attachments': 'Post Dokumente',
    'POST.Drawer_Title_ManageImages' : 'Bilder verwalten',
    'POST.Title_AttachDocuments': 'Dokumente hinzufügen',
    'POST.Title_ManageImages': 'Bilder verwalten',
    'POST.Title_ManageMedia' : 'Medien verwalten',
    'POST.Dragger_Text_Attach' : 'Dokumente hinzufügen',
    'POST.Attachment_Count' : 'Dateien ({ attachmentCount })',
    'POST.Images_Count': 'Bilder ({ imagesCount })',
    'POST.Media_Count' : 'Medien ({ mediaCount })',
    'POST.Avatar_NewsTitle' : 'NEWS',
    'SEARCH.Input_Placeholder_User' : 'Name oder Nutzername eingeben',
    'SEARCH.Input_Placeholder' : 'Suche Starten!',
    'SEARCH.Type_Label_News' : 'News',
    'SEARCH.Type_Label_Posts' : 'Posts',
    'SEARCH.Type_Label_Users' : 'Nutzer',
    'SEARCH.Type_Label_Hashtags' : 'Hashtags',
    'SEARCH.Type_Label_Collections' : 'Kollektionen',
    'SEARCH.Type_Label_Documents' : 'Dokumente',
    'SEARCH.Button_Label_Contacts' : 'Kontakte erhalten',
    'SORT.Label_Newest' : 'Neuste',
    'SORT.Label_Popular' : 'Populär',
    'SORT.Label_MostUsed' : 'Am meisten genutzt',
    'SORT.Label_AtoZ' : 'A bis Z',
    'FILTER.New_Users' : 'Neue Kollegen',
    'FILTER.All_Users' : 'Alle',
    'SUMMARY_COUNTERS.Label_KNYS' : 'KNYS',
    'SUMMARY_COUNTERS.Label_Users' : 'Nutzer',
    'SUMMARY_COUNTERS.Label_Posts' : 'Posts',
    'SUMMARY_COUNTERS.Label_Images' : 'Bilder',
    'SUMMARY_COUNTERS.Label_Videos' : 'Videos',
    'SUMMARY_COUNTERS.Label_Documents' : 'Dokumente',
    'SUMMARY_COUNTERS.Label_Comments' : 'Kommentare',
    'SUMMARY_COUNTERS.Label_Collections' : 'Kollektionen',
    'SUMMARY_COUNTERS.Label_Teams' : 'Teams',
    'SUMMARY_COUNTERS.Label_Hashtags' : 'Hashtags',
    'MENUS.Label_MyProfile' : 'Meine Profil',
    'MENUS.Label_Competition' : 'WETTBEWERB',
    'MENUS.Label_Ranking' : 'RANGFOLGE',
    'GLOBAL.Modal_Title_Menu' : 'Menu',
    'MENUS.Label_Events' : 'VERANSTALTUNGEN',
    'MENUS.Label_Contact' : 'KONTAKT',
    'MENUS.Label_Chat' : 'Chat',
    'CHAT.Button_Text_NewChat': "Neuer Chat",
    'CHAT.Modal_Title_NewChat': "Neuer Chat"
};

export default deutsch;