import React, { useState, useEffect } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Icon, Upload, message } from "antd";

import { POST_ADD_MEDIA, MEDIA_VIDEO_ADD, POST_DELETE_MEDIA } from "graphql/mutation/post-gql";
import { GET_POST_SHORT } from "graphql/query/post-gql";

import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";
import videoProcessingImg from 'assets/video-cover-processing.svg';
import noVideoCover from 'assets/no-video-cover.svg';

import './post-upload-media.css';


const PostUploadMedia = ({ defaultMedia, postId, postSlug }) => {

    const client = useApolloClient();

    const [addMedia, { loading: loadingQueryWithImage }] = useMutation(POST_ADD_MEDIA);
    const [ createPostWithVideo, { loading: loadingQueryWithVideo } ] = useMutation(MEDIA_VIDEO_ADD);
    const [deleteMedia] = useMutation(POST_DELETE_MEDIA);

    const [ filesLoading, setFilesLoading ] = useState(0);

    useEffect(() => {

        const parentElement = document.querySelector('.ant-upload-list.ant-upload-list-picture-card');

        if (parentElement) {
            const elements = parentElement.querySelectorAll('.ant-upload-list-picture-card-container');
            elements.forEach(element => {
                const linkElement = element.querySelector('.ant-upload-list-item-name');
                const link = linkElement.getAttribute('href');

                if (/(\.mp4|\.AVI|\.MPEG|\.WMV|\.MKV|\.3GP|\.WEBM|\.MOV)$/i.test(link)) {
                    element.classList.add('video-item');
                }
            });
        }


        const videoProcessingImage = parentElement.querySelector('img[src*="no-video-cover"], img[src*="video-cover-processing"]');
        const imageElements = parentElement.querySelectorAll('.ant-upload-list-item-image');

        if (videoProcessingImage) {
            imageElements.forEach(imageElement => {
                if (!imageElement.classList.contains('video-processing-or-no-thumbnail')) {
                    imageElement.classList.add('video-processing-or-no-thumbnail');
                }
            });
        } else {
            imageElements.forEach(imageElement => {
                if (imageElement.classList.contains('video-processing-or-no-thumbnail')) {
                    imageElement.classList.remove('video-processing-or-no-thumbnail');
                }
            });
        }

    }, [ defaultMedia ]);


    const fileList = defaultMedia.map(({ id, title, thumbnail, path, extension, status: statusVideo }) => {

        const thumbUrl = (statusVideo === null) ? thumbnail : (statusVideo === 'loaded') ? thumbnail ? thumbnail: noVideoCover : videoProcessingImg

        return (
            {
                uid : id,
                name : title,
                url : path,
                thumbUrl,
                extension,
                status: 'done',
            }
        )
    });

    const loading = loadingQueryWithImage || loadingQueryWithVideo;

    const ruleFileMessage = Localize(({ children: "FORM_RULES.Text_UploadVideo" , size: '40 MB' })).props.children;

    const formUploadProps = {
        accept: 'image/*,video/*',
        beforeUpload: (file) => {
            if (file.type.startsWith('video/'))
            {
                if (file.size / 1024 / 1024 > 40) {
                    message.error(ruleFileMessage);
                    return false;
                }

                setFilesLoading((prevState) => prevState + 1);

                createPostWithVideo({
                    variables: {
                        post_id: postId,
                        file
                    },
                    update(cache, { data }) {
                        const { mediaVideoAdd } = data;
                        const { label, message } = mediaVideoAdd;

                        setFilesLoading((prevState) => prevState - 1);

                        successNotification({
                            title: label,
                            description: message
                        });
                    }
                }).catch((error) => {
                    setFilesLoading((prevState) => prevState - 1);
                    errorNotification(error);
                });
            } else
            {
                setFilesLoading((prevState) => prevState + 1);

                addMedia({
                    variables: {
                        post_id: postId,
                        files: [file]
                    },
                    update(cache, { data }) {

                        const { addMedia } = data;
                        const { label, message } = addMedia;

                        setFilesLoading((prevState) => prevState - 1);

                        successNotification({
                            title: label,
                            description: message
                        });
                    }
                }).catch((error) => {
                    setFilesLoading((prevState) => prevState - 1);
                    errorNotification(error);
                });
            }

            return false;
        },
        onRemove: (file) => {

            if(file.status === 'done' && defaultMedia.length > 1){

                deleteMedia({
                    variables : {
                        media_id : file.uid
                    },
                    update: (cache, { data }) => {

                        const {
                            deleteMedia : {
                                label,
                                message
                            }
                        } = data;

                        const {post} = client.readQuery({
                            query: GET_POST_SHORT,
                            variables: {
                                slug: postSlug,
                            }
                        });

                        const newMedia = post.media.filter(item => item.id !== file.uid);

                        client.writeQuery({
                            query: GET_POST_SHORT,
                            data: {
                                post: { ...post, media:[ ...newMedia ] }
                            },
                        });

                        successNotification({
                            title: label,
                            description: message
                        });

                    }
                }).catch((error) => {
                    errorNotification(error);
                });

            }
        },
        fileList: fileList,
        listType: "picture-card",
        showUploadList: {
            showPreviewIcon: false,
            showDownloadIcon: false
        },
        multiple : true
    };

    return(
        <div className={ `post-update-media ${ fileList.length < 3 ? "center-upload" : ""}`}>
            <Localize wrap="h4" mediaCount={ defaultMedia.length }>
                POST.Media_Count
            </Localize>
            <Upload {...formUploadProps}>
                <div className="upload-label">
                    { loading || filesLoading > 0 ?
                        <Icon type="loading" /> :
                        <>
                            <div className="row">
                                <span className="icon">
                                    <Icons.PlusNewImage />
                                </span>
                                <span className="text">
                                    <Localize>POST.Text_AddImage</Localize>
                                </span>
                            </div>
                            <div className="row">
                                <span className="icon">
                                    <Icons.VideoSroke />
                                </span>
                                <span className="text">
                                    <Localize>POST.Text_AddVideo</Localize>
                                </span>
                            </div>
                        </>
                    }
                </div>
            </Upload>
        </div>
    );
};

export default PostUploadMedia;